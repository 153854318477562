(() => {
    if (!console.timerInfo) {
        Object.defineProperty(console, "timerInfo", {
            get: function () {
                return Function.prototype.bind.call(
                    console.log,
                    console,
                    "%c" + Number(performance.now() / 1e3).toFixed(1) + " Timer:",
                    "color:#03C;font-weight:bold;"
                );
            }
        });
    }
    function removeLoading(attempt = 0) {
        console.log('removing loader, attempt ' + attempt)
        if (document.querySelector("#Loader-General")) {
            setTimeout(() => {
                document.querySelector("#Loader-General").style.display = "none";
                console.timerInfo("removed loader");
            }, 3e3);
        } else if (attempt < 4) {
            console.timerInfo("loader not found, attempt " + attempt);
            setTimeout(() => {
                removeLoading(attempt + 1);
            }, 3e3);
        }
        else {
            console.timerInfo("loader not found, attempt " + attempt);
            return;
        }
    }

    removeLoading();
})();
